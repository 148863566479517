import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Context } from "../App";
import { httpConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
import NavBar from "./common/NavBar";

function truncateUrl(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

function Sidebar(props) {
  const {
    setUrl,
    hamburgerClicked,
    setResult,
    setPhrase,
    setSelectedKeyword,
    count,
    setCount,
    currentSession,
    setCurrentSession,
    setTitle,
    setSidebarClicked,
    setMessage,
    cookieData,
    setCookieData,
  } = useContext(Context);
  const [sessionUrl, setSessionUrl] = useState({});
  const navigate = useNavigate();
  const [reversedSession, setReversedSession] = useState([]);
  const [sessionArray, setSessionArray] = useState(
    {
      count: [],
      data: [],
    },
  );

  var runOnce = false;
  // const [reversedSession,setReversedSession]=useState([])

  useEffect(() => {
    if (!runOnce) {
      getDataFromCookie();
      runOnce = true;
    }
  }, []);

  useEffect(() => {
    setSidebarClicked(false);
    retrieveSessions();

    setReversedSession([...currentSession.message].reverse());
    // setReversedSession(cookieData.reverse());
  }, [currentSession]);

  function getDataFromCookie() {
    console.log("count Value......", count);
   
    for (let i = 1; i < count; i++) {
      sessionArray.data.push(
       
         sessionManager.getDataFromCookies(i)
      
      );
      sessionArray.count.push(i)
    }
  }

  function retrieveSessions() {
    try {
      fetch(
        `${
          process.env.REACT_APP_BASE_URL +
          httpConstants.API_END_POINT.GET_SESSIONS
        }`
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.responseData.length > 0) {
            const reversed = resp.responseData.reverse();

            const latestSessions = reversed.slice(0, 10);
            var tempUrlArray = [];

            latestSessions.map((item) => {
              tempUrlArray.push(item);
            });

            setSessionUrl(tempUrlArray);
            // setSessionUrl(resp.responseData);
          }
        });
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  const resetAll = () => {
    setUrl("");
    setResult([]);
    setPhrase([]);
    setTitle("");
    setSelectedKeyword([]);
    setMessage();
  };

  function retrieveSessionById(id) {
    setSidebarClicked(true);
    resetAll();
    try {
      fetch(
        `${
          process.env.REACT_APP_BASE_URL +
          httpConstants.API_END_POINT.GET_SESSION +
          id
        }`
      ).then((result) => {
        result.json().then((resp) => {
          resp.responseData.map((data) => {
            setUrl(data.url);
            setSelectedKeyword(data.selectedKeyWords);
            setPhrase(data.keyWords);
            setResult(data.suggestions);
            setTitle(data.title);
          });
        });
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  // const showUrl = () => {
  //   setUrlData([sessionManager.getDataFromCookies("url")]);
  // };

  const deleteCount = (index) => {
    const updatedItems = currentSession.message.filter((data, id) => {
      return index !== id;
    });
    sessionManager.removeDataFromCookies(index);
    setCurrentSession({ id: count - 1, message: updatedItems });
    setCount(count - 1);
    // navigate("/");
    resetAll();
  };

  const deleteAllCount = (index) => {
    const updatedItems = currentSession.message.filter((data, id) => {
      return index === id;
    });
    setCurrentSession({ id: 1, message: updatedItems });
    setCount(1);
    // navigate("/");
    resetAll();
  };

  const deleteSessionById = (id) => {
    try {
      fetch(
        `${
          process.env.REACT_APP_BASE_URL +
          httpConstants.API_END_POINT.DELETE_SESSION +
          id
        }`,
        { method: "DELETE" }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.responseCode === 200) {
            retrieveSessions();
          }
        });
      });
    } catch (err) {
      console.log("error", err);
    }
  };

  console.log("get cookies.....", sessionArray);
 

  return (
    <div
      className={` h-full  bg-black-25 mobile:bg-black-75 tablet:bg-black-75 tablet:w-screen tablet:h-screen  mobile:w-screen  mobile:h-screen   `}
    >
      <div className="mobile:flex tablet:flex hidden"><NavBar setHamburger={props.setHamburger} hamburger={props.hamburger}/></div>
      
      <nav className=" px-6 pt-6 pb-8 flex h-full flex-1 flex-col mobile:pt-26 mobile:px-4 mobile:pb-6 tablet:pt-26 tablet:px-4 tablet:pb-6">
        <div
          onClick={() => {
            navigate("/");
            resetAll();
          }}
          className="flex h-14 pl-4 gap-3 font-HelveticaNeue font-normal text-ft8 mobile:text-ft9 text-babyBlue-25   items-center  border-babyBlue-25 border-2 w-74.5 mobile:w-full tablet:w-full  cursor-pointer   flex-shrink-0 "
        >
         
          <img
            src="./images/plus_icon_blue.svg"
            alt="plus-icon"
            className="w-3 h-3 "
          />
          Enter New URL
        </div>

        <div className="flex-col flex-1  mt-8 mobile:mt-7.5 tablet:mt-7.5 overflow-y-auto ">
          {
            //sessionUrl //count //currentSession.message //reversedSession

            reversedSession.map((data, index) => (
              <div className="flex flex-col mb-2.5 " key={index}>
                <div className="bg-babyBlue-25 relative flex h-14 px-4 mobile:px-2 tablet:px-2 items-center   cursor-pointer break-all   animate-flash ">
                  <img
                    src="./images/link_icon.svg"
                    alt=""
                    className="w-3.5 h-3.5 mr-3  "
                  />
                  <div
                    onClick={() => {
                      resetAll();
                      setUrl(data);
                      cookieData.length !== 0
                        ? navigate("/result")
                        : navigate("/");
                    }}
                    className="mobile:hidden tablet:hidden text-offWhite-25 font-HelveticaNeue font-normal text-ft8 mr-5  flex flex-1  overflow-hidden break-all relative    items-center"
                  >
                    {/* {url} */}
                    {/* {truncateUrl(index>=0?urlData : '',30)}  */}
                    {/* {truncateUrl(index > 0 ? data : "", 30)} */}
                    {truncateUrl(index >= 0 ? data : "", 24)}
                  </div>
                  <div
                    onClick={() => {
                      resetAll();
                      setUrl(data);
                      navigate("/");
                    }}
                    className="mobile:flex tablet:flex hidden   text-offWhite-25 font-HelveticaNeue font-normal  mr-1 mobile:text-ft9  tablet:text-ft9 flex-1 mobile:flex-none tablet:flex-none    overflow-hidden break-all relative    items-center"
                  >
                    {/* {url} */}
                    {/* {truncateUrl(index>=0?urlData : '',10)}  */}
                    {/* {truncateUrl(index > 0 ? data : "", 10)} */}
                    {truncateUrl(index >= 0 ? data : "", 26)}
                    
                  </div>

                  <img
                    onClick={() => deleteCount(index)}
                    src="./images/delete_icon.svg"
                    alt=""
                    className="w-4.5 h-4.5 mobile:w-4 mobile:h-4  ml-auto"
                  />
                </div>
              </div>
            ))
          }
          {
            // //sessionUrl //count //currentSession.message //reversedSession
            // sessionUrl.length > 0 &&
            //   sessionUrl.map((data, index) => (
            //     <div
            //       className="flex flex-col mb-2.5 "
            //       key={index}
            //     >
            //       <div className=" bg-babyBlue-25 flex h-14 px-4 mobile:px-2  items-center   cursor-pointer break-all   animate-flash ">
            //         <img
            //           src="./images/link_icon.svg"
            //           alt=""
            //           className="w-3.5 h-3.5 mr-3 mobile:w-3 mobile:h-3 "
            //         />
            //         <div
            //           onClick={() => {
            //             retrieveSessionById(data._id);
            //             navigate("/result");
            //           }}
            //           className="mobile:hidden tablet:hidden text-offWhite-25 font-HelveticaNeue font-normal text-ft8 mr-5  flex flex-1 mobile:flex-none    overflow-hidden break-all relative  mobile:text-sm  items-center"
            //         >
            //           {/* {url} */}
            //           {/* {truncateUrl(index>=0?urlData : '',30)}  */}
            //           {/* {truncateUrl(index > 0 ? data : "", 30)} */}
            //           {truncateUrl(index >= 0 ? data.url : "", 24)}
            //         </div>
            //         <div  onClick={() => {
            //             retrieveSessionById(data._id);
            //             navigate("/result");
            //           }} className="mobile:flex tablet:flex hidden   text-offWhite-25 font-HelveticaNeue font-normal text-ft8 mr-5   flex-1 mobile:flex-none    overflow-hidden break-all relative  mobile:text-sm  items-center">
            //           {/* {url} */}
            //           {/* {truncateUrl(index>=0?urlData : '',10)}  */}
            //           {/* {truncateUrl(index > 0 ? data : "", 10)} */}
            //           {truncateUrl(index >= 0 ? data.url : "", window.innerWidth > 767 ? 15 : 10 )}
            //         </div>
            //         <img
            //           onClick={() => {
            //             deleteSessionById(data._id);
            //           }}
            //           src="./images/delete_icon.svg"
            //           alt=""
            //           className="w-4.5 h-4.5 mobile:w-3 mobile:h-3"
            //         />
            //       </div>
            //     </div>
            //   ))
          }
        </div>

        <div
          onClick={() => {
            deleteAllCount();
          }}
          className=" flex h-14  items-center gap-4.5 mobile:gap-4 tablet:gap-4  cursor-pointer"
        >
          <img src="./images/delete_icon_blue.svg" alt="" className="w-5 h-5 mobile:w-4.5 mobile:h-4.5 " />
          <text className=" font-HelveticaNeue font-normal text-ft8  text-babyBlue-25">Clear</text>
        </div>
        {/* <div
          onClick={() => window.open("https://discord.com/", "_blank")}
          className="flex  gap-4.5 mobile:gap-4 h-14  items-center    cursor-pointer"
        >
          <img
            src="./images/discord_icon.svg"
            alt=""
            className="w-5 h-5  mobile:w-4.5 mobile:h-4.5"
          />
          <text className=" font-HelveticaNeue font-normal text-ft8 mobile:text-ft8 text-babyBlue-25">Discord</text>
        </div> */}
        <div
          onClick={() => {
            navigate("/faq");
          }}
          className="flex   gap-4.5 mobile:gap-4 h-14   items-center  cursor-pointer "
        >
          <img src="./images/faq_icon.svg" alt="" className="w-5 h-5 mobile:w-4.5 mobile:h-4.5 " />
          <text className=" font-HelveticaNeue font-normal text-ft8  text-babyBlue-25">FAQ</text>
        </div>
        {/* <div className="flex   gap-4.5 mobile:gap-4 h-14   items-center  cursor-pointer ">
          <img src="./images/logout_icon.svg" alt="" className="w-5 h-5 mobile:w-4.5 mobile:h-4.5 " />
          <text className="  font-HelveticaNeue font-normal text-ft8 mobile:text-ft8 text-babyBlue-25">Log Out</text>
        </div> */}
      </nav>
    </div>
  );
}

export default Sidebar;
