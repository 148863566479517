import React from 'react'

function Faq() {
  return (
    <div className='flex flex-col    mx-2'>
        <text className=' text-3xl font-semibold text-center mt-10'>FAQ</text>
      
        </div>
  )
}

export default Faq