import React,{useState,useEffect,useContext} from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import { Context } from "../../App";
// import { makeStyles } from '@material-ui/core/styles';


// const useStyles = makeStyles(() => ({
//   paper: {
//     width: "397px !important",
//     alignSelf: "baseline",
//     marginTop: "200px !important",
//     zIndex: "130000 !important",
//     borderRadius: "18px !important",
//     paddingLeft: "16px !important",
//     paddingRight: "16px !important",
//     paddingBottom: "40px !important",
//   },
//   "@media(max-width:1024px)": {
//     paper: {
//       marginTop: "140px !important",
//     },
//   },
// }));



function DialogBox(props) {
  const navigate=useNavigate();
  // const classes = useStyles();
  const {openDialog,setOpenDialog}=useContext(Context);



  
  return (
    // <div className=" bg-black inset-0 opacity-90 absolute flex flex-col justify-center items-center  border-8">
    //   <div className=" text-black bg-white opacity-100  h-80 w-80 border-4 rounded-xl flex justify-center items-end ">
    //    <div className=" w-full h-full flex flex-col items-center justify-between">
    //     <h2 className=" mt-10 text-xl font-semibold " >Message</h2>
    //    <button  onClick={()=>{navigate('/')}} className=" mb-10 bg-slate-800 place-self-center hover:bg-slate-500 text-white w-32 place-content-center flex  font-bold  py-2 px-4 rounded">
    //       OK
    //     </button>
    //    </div>
    //   </div>Qui enim aute nisi aute amet enim excepteur cupidatat officia consectetur elit qui anim.
    // </div>
    <div className=" absolute flex flex-col ">
    
      <Dialog open={openDialog} className={'flex items-center justify-center '}  >
      <div className="  text-center flex border-4 w-96 h-48 mobile:w-full  bg-blueGrey-25 ">
            
      <div className="flex flex-col w-full mx-2 my-2  justify-center">
         <h2 className=" font-HelveticaNeue font-semibold text-ft4    px-2 " >Message</h2>
         <p className=" font-HelveticaNeue font-medium text-ft5   px-4" >{props.message} </p>

        <button onClick={()=>{setOpenDialog(false);}}   className="w-26 h-10   flex justify-center items-center place-self-center  bg-babyBlue-25 font-HelveticaNeue font-medium text-ft4 text-offWhite-25 ">
          OK
         </button>
        </div>
           
          </div>
    </Dialog>
    </div>
  );
}

export default DialogBox;
