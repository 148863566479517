import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { Context } from "../App";
import React, { useContext } from "react";
import { sessionManager } from "../managers/sessionManager";
import { httpConstants } from "../constants";
// import Loader from "../constants/loader";
import DialogBox from "./common/DialogBox";
import Sidebar from "./Sidebar";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material";
import Loader from "./common/Loader";
import { json } from "react-router-dom";
import NavBar from "./common/NavBar";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#182220",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    fontWeight: 400,
  },
}));

function Result() {
  const {
    phrase,
    url,
    selectedKeyword,
    title,
    result,
    currentSession,
    sidebarClicked,
    setSidebarClicked,
    setResult,
    setTitle,
    openDialog,
    message,
    setMessage,
    cookieData,
    setCookieData,
  } = useContext(Context);
  // const [loader, setLoader] = useState(true);
  const [dataDownload, setDataDownload] = useState([]);
  const [loader, setLoader] = useState(false);
  const [regenerate, setRegenerate] = useState(false);
  const [hamburger,setHamburger]=useState(false);
  var runOnce = false;
  // useEffect(()=>{
  //   getTextSuggestion()
  // },[selectedKeyword])

  useEffect(() => {
    // if(result ==0){sessionManager.deleteAllCookie()}
    if (!runOnce) {
      console.log("value on result in useEffect", result);
      addSession();
      runOnce = true;
    }
    if(result.length !=0) {storeDataInCookies()}
  }, []);

  useEffect(() => {
    addSession();
  }, [regenerate]);

  useEffect(() => {
    result.length != 0
      ? result.map((data) => {
          setDataDownload(data);
        })
      : console.log("result123", result);
    console.log(url);
    if(result.length !=0) {storeDataInCookies()}
  }, [result]);

  function storeDataInCookies() {
    // console.log("storing cookie");

    // const array = {
    //   url: url,
    //   selectedKeyword: selectedKeyword,
    //   title: title,
    //   result: result,
    // };

    // const arrayString = JSON.stringify(array);

    // setCookieData([...cookieData, arrayString]);

    sessionManager.setDataInCookies(
      [url, title, selectedKeyword, result],
      currentSession.id
    );

  }

  

  function addSession() {
    console.log("in add session", result.length, regenerate);

    if (result.length > 0 && sidebarClicked === true) {
      setLoader(false);
    }
    try {
      if (
        (result.length > 0 && sidebarClicked === false) ||
        (result.length > 0 && regenerate === true)
      ) {
        
      
        // storeDataInCookies();
        // sessionManager.setDataInCookies(
        //   cookieData,
        //   currentSession.id
        // );

        fetch(
          `${
            process.env.REACT_APP_BASE_URL +
            httpConstants.API_END_POINT.ADD_SESSION
          }`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              url: url,
              keyWords: phrase,
              selectedKeyWords:
                selectedKeyword.length > 0 ? selectedKeyword : [""],
              suggestions: result,
              title: title,
            }),
          }
        ).then((resp) => {
          if (resp.responseCode === 200) {
            setLoader(false);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleButtonClick() {
    setResult([]);

    if (
      (selectedKeyword.length == 1 && selectedKeyword[0] == "") ||
      (selectedKeyword.length == 0 && selectedKeyword != undefined)
    ) {
      setMessage("Please Enter or Select a Keyword.");
      // alert("Please Enter or Select a Keyword.")
      // setOpenDialog(true);
    } else {
      try {
        console.log("abc123", url, title, selectedKeyword);
        setLoader(true);

        await fetch(
          `${
            process.env.REACT_APP_BASE_URL +
            httpConstants.API_END_POINT.GET_TEXT_SUGGESTIONS
          }`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              url: url,
              ownKeyWords: selectedKeyword,
            }),
          }
        ).then((result) => {
          result.json().then((resp) => {
            if (resp.responseCode === 200) {
              setResult(resp.responseData.suggestions);
              setTitle(resp.responseData.title);

              setLoader(false);
              setRegenerate(true);
              console.log("addSession call");
              addSession();
              // navigate("/result");
            } else {
              console.log("data not received");
              setMessage(resp.message);
              // setOpenDialog(true);
              // alert(resp.message);
              setLoader(false);
              setRegenerate(false);
            }
          });
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  return (
    <div className="flex w-full  h-screen mobile:w-screen  ">
      <div className={` ${hamburger ? 'mobile:flex mobile:w-full mobile:h-full tablet:flex tablet:w-full ' : 'mobile:hidden tablet:hidden w-174'}`}>
        <Sidebar setHamburger={setHamburger} hamburger={hamburger}/>
      </div>
      
      <div className=" overflow-y-auto flex w-full bg-white-100  flex-col px-12.75 pt-11 pb-15.25 mobile:pt-0 mobile:pb-0 mobile:px-0  tablet:pt-0 tablet:pb-0 tablet:px-0 ">
      <div className="mb-24 mobile:flex tablet:flex hidden">
          <NavBar setHamburger={setHamburger} hamburger={hamburger}/>
        </div>
        <div className=" flex justify-between tablet:flex-col tablet:gap-4 tablet:mx-4 mobile:flex-col mobile:gap-4 mobile:mx-4 ">
          <div>
            <h1 className=" font-bold font-HelveticaNeue  text-ft4  mb-2 mobile:font-medium tablet:font-semibold">
              {title}
             
              {/* Adipisicing in non exercitation enim officia sit. */}
            </h1>
            <a
              className=" font-HelveticaNeue font-normal text-ft7 text-babyBlue-25 underline"
              target="_blank"
              href={url}
              rel="noreferrer"
            >
              {url}
              {/* https://www.leewayhertz.com */}
            </a>
          </div>
          <div className={` ${!hamburger ? 'mobile:flex tablet:flex' : 'mobile:hidden tablet:hidden'} flex gap-2 mt-1 mobile:inset-x-0 mobile:fixed mobile:bottom-0 mobile:mt-0 mobile:bg-white-100  mobile:pt-0 mobile:pb-1.5  mobile:px-4 tablet:inset-x-0 tablet:fixed tablet:bottom-0 tablet:mt-0 tablet:bg-white-100  tablet:pt-0 tablet:pb-1.5 tablet:px-4 `}>
            <LightTooltip title="Re-generate" placement="left-start">
              <button
                onClick={() => handleButtonClick()}
                className=" box-border border-2 h-12 w-13.25 border-babyBlue-25 flex justify-center items-center"
              >
                <img
                  src="./images/regenerate_icon.svg"
                  alt="re-generate_icon"
                />
              </button>
            </LightTooltip>
            <LightTooltip title="Download" placement="left-start">
              <button className=" box-border border-2 h-12 w-13.25 border-babyBlue-25  flex  justify-center items-center  ">
                <CSVLink data={dataDownload}>
                  <img src="./images/download_icon.svg" alt="download_icon" />
                </CSVLink>
              </button>
            </LightTooltip>
            <button className="  w-38.5 mobile:w-full tablet:w-full   h-12 bg-babyBlue-25 font-HelveticaNeue font-normal text-ft7 text-offWhite-25 flex items-center justify-center   ">
              Fix it for me
            </button>
          </div>
        </div>
        <div className="mt-6 mobile:mt-4 mobile:mb-9 mobile:mx-4  tablet:mt-4 tablet:mb-9 tablet:mx-4">
          <text className=" text-black-25 font-HelveticaNeue font-medium text-ft6 mobile:font-normal">
            Keywords:
          </text>
          <text className=" text-black-25 font-HelveticaNeue font-bold text-ft6 ">
            {`${selectedKeyword.join(", ")}`}
            {/* Ullamco,occaecat, commodo */}
          </text>
        </div>

        <table className="mt-7.5 grid mobile:hidden tablet:hidden ">
          <tr className=" h-14 grid grid-cols-3  ">
            <th className=" flex items-center border  bg-black-25 pl-5 gap-2.5 ">
              <img
                src="./images/link2_icon.svg"
                alt="link_icon"
                className="w-5 h-5"
              />
              <text className=" font-HelveticaNeue font-medium text-ft7 text-offWhite-25">
                Original
              </text>
            </th>
            <th className=" flex pl-5 gap-2.5   items-center border bg-babyBlue-25">
              <img
                src="./images/suggestion_icon.svg"
                alt="suggestion_icon"
                className="w-6 h-6"
              />
              <text className="font-HelveticaNeue text-white-100  font-medium text-ft7">
                Suggestion 1
              </text>
            </th>
            <th className="flex pl-5 gap-2.5  items-center border bg-babyBlue-25">
              <img
                src="./images/suggestion_icon.svg"
                alt="suggestion_icon"
                className="w-6 h-6"
              />
              <text className="font-HelveticaNeue text-white-100 font-medium text-ft7">
                Suggestion 2
              </text>
            </th>
          </tr>
          {result.map((data) =>
            data.map((finalData) => (
              <tr className="grid grid-cols-3 bg-gradient-to-r from-babyBlue-50  to-babyBlue-75 ">
                <td className="pr-0   font-HelveticaNeue font-normal text-black-50  text-ft3  drop-shadow-1xl">
                  <div className=" bg-white-100 w-full h-full px-4 py-4  ">
                    {finalData.original}
                  </div>
                </td>
                <td className="pr-0  font-HelveticaNeue font-medium  text-ft3 drop-shadow-1xl  ">
                  <div className="bg-white-100   px-4 py-4 w-full h-full ">
                    {finalData.V1}
                  </div>
                </td>
                <td className="b-0.18 font-HelveticaNeue font-medium text-ft3 drop-shadow-1xl ">
                  <div className="bg-white-100  px-4 py-4 w-full h-full ">
                    {finalData.V2}
                  </div>
                </td>
              </tr>
            ))
          )}
          {/* <tr className="grid grid-cols-3  ">
          <td className="border px-4 py-4 font-HelveticaNeue font-normal text-green-75 text-ft7 ">Nisi velit sunt duis aute ea.Pariatur proidentDolore duis Lorem enim qui deserunt quis irure consectetur ipsum. cillum in proident ipsum consectetur pariatur pariatur mollit sit mollit consectetur.</td>
          <td className="border px-4 py-4 font-HelveticaNeue font-normal text-green-75 text-ft7 ">Sit labore ipsum ea ad dolor excepteur sint reprehenderit Lorem est laboris reprehenderit.</td>
          <td className="border px-4 py-4 font-HelveticaNeue font-normal text-green-75 text-ft7 ">Fugiat veniam sit deserunt ut elit.</td>
        </tr>  */}
        </table>
        {result.map((data) =>
            data.map((finalData) => (
        <div className=" gap-4 hidden  mobile:flex mobile:flex-col mobile:mb-6 mobile:mx-4 tablet:flex tablet:flex-col tablet:mb-6 tablet:mx-4 ">
          <div className=" mobile:max-w-xs  tablet:w-114.75 ml-auto">
            <div className="h-14 border-2 flex items-center justify-end bg-black-25 pr-5 gap-3 ">
            <img
                src="./images/link2_icon.svg"
                alt="link_icon"
                className="w-5 h-5 mobile:w-6 mobile:h-6 tablet:w-6 tablet:h-6"
              />
              <text className=" font-HelveticaNeue  text-offWhite-25 mobile:text-ft14 mobile:font-medium tablet:text-ft14 tablet:font-medium">
                Original
              </text>
              </div>
            <div className="border-2  border-t-0 text-right    font-HelveticaNeue font-normal text-black-50 text-ft9 tablet:text-ft14  tablet:font-medium bg-white-100   px-6 py-4"> {finalData.original}</div>
          </div>
          <div className=" mobile:max-w-xs  tablet:w-114.75 ">
            <div className="h-14 border-2 flex pl-6 gap-2.5   items-center  bg-babyBlue-25"> 
              <text className="font-HelveticaNeue text-white-100   mobile:font-normal mobile:text-ft14 tablet:font-medium tablet:text-ft14">
                Suggestions by
              </text>
              
             <img src="./images/img_logo.svg"
                alt="img_logo"
                className="w-14 h-5 -ml-0.5 pt-1"
              />
              </div>
            <div className="border-r-2 border-l-2  font-HelveticaNeue  bg-white-100   px-6 py-4 mobile:font-normal mobile:text-ft9 tablet:font-medium tablet:text-ft14">{finalData.V1}</div>
            <div className="border-2  font-HelveticaNeue  bg-white-100    px-6 py-4 mobile:font-normal mobile:text-ft9 tablet:font-medium tablet:text-ft14">{finalData.V2}</div>
          </div>

        </div>
         ))
         )}

        <div className="flex mt-96 justify-items-center place-self-center  ">
          <Loader page={"keyword"} visible={loader} />
        </div>
      </div>
      {/* <div className={` ${openDialog ? "flex" : "hidden"} `}>
     <DialogBox
       showDialog={openDialog}
       message={`${message  ? message : "Please Try Again"}`}
     />
   </div> */}
    </div>
  );
}

export default Result;
