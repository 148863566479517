import "./App.css";
import Main from "./components/Main";
import { createContext, useState } from "react";
import Sidebar from "./components/Sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GenerateTerm from "./components/GenerateTerm";
import Faq from "./components/Faq";
import Result from "./components/Result";
import DialogBox from "./components/common/DialogBox";
import Home from "./components/Home";


export const Context = createContext();
function App() {
  const [phrase, setPhrase] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([""]);
  const [result, setResult] = useState([]);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState();
  const [hamburgerClicked, setHamburgerClicked] = useState(false);
  const [count, setCount] = useState(1);
  const [currentSession, setCurrentSession] = useState({
    id: count,
    message: [],
  });
  const [sidebarClicked, setSidebarClicked] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(true);
  const [openDialog, setOpenDialog] = useState(true);
  const [message, setMessage] = useState("");
  const [cookieData,setCookieData]=useState([])
  const [cookieCount,setCookieCount]=useState(1)

  return (
    <div className="flex w-full h-full ">
      <BrowserRouter>
        <Context.Provider
          value={{
            phrase,
            setPhrase,
            url,
            setUrl,
            hamburgerClicked,
            title,
            setTitle,
            selectedKeyword,
            setSelectedKeyword,
            result,
            setResult,
            count,
            setCount,
            currentSession,
            setCurrentSession,
            sidebarClicked,
            setSidebarClicked,
            loaderVisible,
             setLoaderVisible,
             openDialog,
             setOpenDialog,
             message,
             setMessage,
             cookieData,
             setCookieData
          }}
        >
          {/* <Sidebar /> */}
          {/* <DialogBox /> */}
          {/* <div
            className={`flex flex-col  w-4/5 ${
              hamburgerClicked ? "mobile:w-3/5" : "mobile:w-full"
            }  tablet:3/5  `}
          > */}
            {/* <div
              onClick={() => setHamburgerClicked(!hamburgerClicked)}
              className="hidden mobile:flex h-10 w-10 hover:cursor-pointer ml-6"
            >
              <img src="./images/hamburger_icon.svg" alt="hamburger_icon" />
            </div> */}
            <Routes>
              {/* <Route path="/" element={<Main />} /> */}
              <Route path="/" element={<Home />} />
              <Route path="/keyword" element={<GenerateTerm />} />
              <Route path="/result" element={<Result />} />
              <Route path="/faq" element={<Faq />} />
          
            </Routes>
          {/* </div> */}
        </Context.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
