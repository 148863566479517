import React, { useState, useContext, useEffect } from "react";
import { httpConstants } from "../constants";
import { Context } from "../App";
import { useNavigate } from "react-router-dom";
import Loader from "./common/Loader";
import DialogBox from "./common/DialogBox";
import Sidebar from "./Sidebar";
import { sessionManager } from "../managers/sessionManager";
import NavBar from "./common/NavBar";

function GenerateTerm(props) {
  const [value, setValue] = useState([""]);
  const [inputValue, setInputValue] = useState([""]);
  const [keywordArray, setkeywordArray] = useState([]);
  const [ownKeywordArray, setOwnKeywordArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [indexArray, setIndexArray] = useState([""]);
  const {
    phrase,
    url,
    selectedKeyword,
    setSelectedKeyword,
    setTitle,
    setResult,
    setSidebarClicked,
    loaderVisible,
    openDialog,
    setOpenDialog,
    message,
    setMessage,
    setCookieCount,
    cookieCount
  } = useContext(Context);

  const keyWords = Object.entries(phrase);

  const navigate = useNavigate();
  const [hamburger,setHamburger]=useState(false);

  useEffect(() => {
    // setValue(['']);
    // setSelectedKeyword(['']);

    setSelectedValue();
  }, [value]);

  useEffect(() => {
    const newArray = [...new Set([...ownKeywordArray, ...keywordArray])];
   const lowerCaseArray=   newArray.map((element)=>element.toLowerCase())
    setSelectedKeyword(lowerCaseArray);
  }, [ownKeywordArray, keywordArray]);


  function storeDataInCookies(url,keyword,title,data){
  
console.log("in cookie function",url,keyword,title,data)
    sessionManager.setDataInCookies(data,cookieCount)
    setCookieCount(cookieCount+1)

  }

  async function handleButtonClick() {
    if (
      (selectedKeyword.length == 1 && selectedKeyword[0] == "") ||
      (selectedKeyword.length == 0 && selectedKeyword != undefined)
    ) {
      setMessage("Please Enter or Select a Keyword.");
      // alert("Please Enter or Select a Keyword.")
      setOpenDialog(true);
    } else {
      try {
        setLoader(true);
        console.log("in Api call");
       
        await fetch(
          `${
            process.env.REACT_APP_BASE_URL +
            httpConstants.API_END_POINT.GET_TEXT_SUGGESTIONS
          }`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              url: url,
              ownKeyWords: selectedKeyword,
            }),
          }
        ).then((result) => {
        
          result.json().then((resp) => {
            if (resp.responseCode === 200) {
          
             
              setResult(resp.responseData.suggestions);
              setTitle(resp.responseData.title);
              setLoader(false);
              
              navigate("/result");
            } else {
              console.log("data not received");
              setMessage(resp.message);
              setOpenDialog(true);
              // alert(resp.message);
              setLoader(false)
            }
          });
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  const getSelectedValue = (word, status) => {
    if (
      (keywordArray.length == 1 && keywordArray[0] == "") ||
      (keywordArray.length == 0 && word != undefined)
    ) {
      setkeywordArray([word]);
    }

    setValue([{ word, status }]);
  };

  function setSelectedValue() {
    if (value[0].status === true && value[0].word != keywordArray[0]) {
      setkeywordArray((prevState) => [...prevState, value[0].word]);
    }
    if (value[0].status === false) {
      setkeywordArray(keywordArray.filter((data) => data !== value[0].word));
    }
  }

  function seprateValue() {
    const array = inputValue[0].replace(/\s+/g, " ").split(",");

    if (array != "" && array !== undefined) {
      setOwnKeywordArray(array);
    }
    if (array == "") {
      setOwnKeywordArray([]);
    }
  }

  console.log("keyword", selectedKeyword);

  return (
    <div className=" flex h-screen w-full mobile:h-screen ">
      
    <div className="w-full flex ">
      <div className={` ${hamburger ? 'mobile:flex tablet:flex mobile:w-full mobile:h-full  ' : 'mobile:hidden tablet:hidden w-174'}`} >
      
        <Sidebar setHamburger={setHamburger} hamburger={hamburger}/>
        
      </div>
     
      <div className={` w-full h-full ${hamburger ? 'mobile:hidden tablet:hidden' : 'mobile:flex tablet:flex'}  bg-blueGrey-25  flex-col px-12.75 pt-11 mobile:px-0 mobile:pt-0 tablet:px-0 tablet:pt-0`}   >
      <div className="mb-16 mobile:flex tablet:flex hidden">
          <NavBar setHamburger={setHamburger} hamburger={hamburger}/>
        </div>
        <div className="mb-4 mobile:flex tablet:flex hidden">
          <Loader page={"keyword"} visible={loader}  />
        </div>
        <div className="flex flex-col mb-12.75 mobile:p-4 tablet:p-4 ">
          <span className=" mb-8 font-HelveticaNeue  text-ft4 font-medium mobile:text-lg mobile:font-Helvetica mobile:text-ft7 mobile: black-50 mobile:font-bold tablet:font-Helvetica tablet:text-ft7 tablet: black-50 tablet:font-bold">
            What Search Terms should be used to find this webpage on Google?
          </span>

          {/* {keyWords.map((word, index) => (
            <label
              onClick={(e) => {
                getSelectedValue(e.target.value, e.target.checked);
              }}
              className="flex py-3 px-3 mobile:px-2 mobile:py-2 items-center gap-3 rounded-md bg-gray-500/10 transition-colors duration-200  cursor-pointer text-sm w-2/4 mobile:w-3/4 justify-between ml-6"
            >
              {word}
              <input type="checkbox" value={word} />
            </label>
          ))}
           */}
          {/* <div>
                <Loader visible={loaderVisible} />
              </div> */}
          <div className="gap-y-4 gap-x-20 grid grid-rows-5 grid-flow-col auto-cols-max mobile:grid-flow-row mobile:h-90 mobile:block mobile:overflow-scroll tablet:h-90 tablet:block tablet:overflow-scroll ">
            {keyWords.map(([word, number], index) => (
              <label
                onClick={(e) => {
                 
                  var indexBoolean;
               
               indexBoolean= indexArray.includes(index);
                  if (indexBoolean === false) {
                    
                    indexArray.push(index);
                  } if(indexBoolean === true) {
                    
                    setIndexArray(indexArray.filter(
                      (number) => number != index
                    ))
                    console.log("error here",indexArray.filter(
                        (number) => number != index
                      ))
                    
                  }
                  console.log("456........",indexArray)
                  getSelectedValue(word, indexBoolean === undefined ? (()=>{indexBoolean=true;return indexBoolean})() : !indexBoolean);
                }}
                className="flex  mobile:mb-4 tablet:mb-4 h-11.5"
              >
                <div className={` ${keywordArray.includes(word) ? ' bg-babyBlue-25': 'bg-lightGreyishGreen-50' }  w-14 flex justify-center items-center`}>
                  <text className={`${keywordArray.includes(word) ? ' text-white-100': 'text-babyBlue-25' }   font-HelveticaNeue font-normal text-ft7`}>
                    {number}
                  </text>
                </div>
                <div className={` ${keywordArray.includes(word) ? ' bg-babyBlue-25 border-l-2 border-white-100': 'bg-white-100' }  w-30.5 flex justify-center items-center`}  >
                  <text className={` ${keywordArray.includes(word) ? ' text-offWhite-25': 'text-black-25' }   font-HelveticaNeue font-normal text-ft7`}>
                    {word}
                  </text>
                </div>
              </label>
            ))}
          </div>
          <text className="mt-5 font-HelveticaNeue font-normal text-ft9 text-ligtGrey-25">
            Click on a keyword to select
          </text>
        </div>
        <div className="flex flex-col items-start  mobile:p-4 tablet:p-4">
          <label className="mb-8 font-medium font-HelveticaNeue text-ft4  mobile:text-lg mobile:font-normal ">
            Enter Your Own Keyword
          </label>
          <div className="flex gap-2 h-14 mobile:w-full">
            <div className="flex border-0  w-114.75  pl-0.5 py-0.5 pr-0.18 bg-gradient-to-r from-babyBlue-50  to-babyBlue-75  mobile:flex mobile:h-12   tablet:flex tablet:h-12">
            <input
              type="text"
              onChange={(e) => {
                setInputValue([e.target.value]);
              }}
              onBlur={seprateValue}
              
              className="w-full h-full focus:outline-none   mr-0.5 pl-5 font-normal text-ft7  text-black-50 "
              // className="mobile:text-lg mobile:h-10  h-12  place-self-start  resize-none border-1 w-1/2 mobile:w-full  bg-transparent p-0 pl-2 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent shadow-[0_0_10px_rgba(0,0,0,0.10)] rounded-md "
            />
            </div>
            <button
              onClick={() => {
                // seprateValue();

                setSidebarClicked(false);
                handleButtonClick();
              }}
              className="w-34.75   bg-babyBlue-25 font-HelveticaNeue font-medium text-ft4 text-offWhite-25  mobile:h-12 mobile:w-26.25 mobile:text-ft9  tablet:h-12 tablet:w-26.25 tablet:text-ft9"
            >
              Generate
            </button>
            
          </div>
        </div>
        <div className="mt-26  mobile:hidden tablet:hidden">
          <Loader page={"keyword"} visible={loader}  />
        </div>

        {/* <div className={`${show1 ? "" : "hidden"} mt-8`}>
        <Result data={data} url={newUrl} title={title} selectedKeyword={keywordArray} keywords={phrase} />
      </div> */}
        {/* <div className={` ${openDialog ? "flex" : "hidden"} `}>
        {console.log("message",message)}
        <DialogBox
          showDialog={openDialog}
          message={`${message  }`}
        />
      </div> */}
      </div>
    </div>
    <DialogBox
          showDialog={openDialog}
          message={`${message  }`}
        />
    </div>
  );
}

export default GenerateTerm;
