import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../Sidebar';

function NavBar(props) {
  const navigate=useNavigate();




  return (
    <div className='flex  bg-top  items-center px-4  h-16 fixed w-full' style={props.hamburger ? {backgroundColor:'#1E1E1E'}:{ backgroundImage: `url(./images/home_gradient_img.svg)` }} >
        <img onClick={()=>props.setHamburger(true)} src="./images/hamburger-icon.svg" alt="hamburger_icon" className={`${props.pageName==='home' ? 'hidden': 'flex'} ${props.hamburger ? 'hidden': 'flex'} mr-3 w-5 h-4 place-self-center tablet:mr-7.5 `}/>
        <img onClick={()=>props.setHamburger(false)} src="./images/close_icon.svg" alt="hamburger_icon" className={`${props.hamburger ? 'flex': 'hidden'} mr-3 w-7 h-7 place-self-center `}/>
        {/* <text className=' font-medium font-HelveticaNeue text-ft11 text-white-100'>
        ReCrisp
        </text> */}
        <img  src="./images/img_logo.svg" alt="img_logo" className='flex place-self-center mt-1' />
        <img onClick={()=>navigate('/')} src="./images/plus_icon.svg" alt="plus_icon" className={`${props.pageName==='home' ? 'hidden': 'flex'} ml-auto w-5 h-5 `}/>
    </div>
  )
}

export default NavBar