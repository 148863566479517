import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';


// import { makeStyles } from '@mui/styles';

// const useStyles=makeStyles({
//   linearProgress:{
  
//     backgroundColor:'white',
//     color:'#2F4742',
//   }
// })


function Loader(props) {
  // const classes=useStyles();

  return(
  
  <div className={` ${props.visible ? 'flex' :'hidden'} flex-col ${props.page == 'home' ? ' items-center': ' items-start'}   gap-4`} >
    <text className={` font-HelveticaNeue font-normal text-ft8 ${props.page == 'home' ? ' text-white-100 mobile:hidden tablet:hidden ': ' hidden'}`}>loading results...</text>
    <text  className={` font-HelveticaNeue font-normal text-ft8 ${props.page == 'home'  ? ' hidden': ' text-black-25 mobile:hidden tablet:hidden'}`}>loading results...It will take 1-2 minutes.</text>
    {/* <ProgressBar now={60} className='w-148 ' /> */}

    <LinearProgress
              // variant="determinate"
              value={props.value} 
              color='inherit'
              className={`  w-148 mobile:w-screen tablet:w-screen `}
              style={{
                color: 'white', 
                backgroundImage:'linear-gradient(to right,#29B3FF , #765EFF)',
                height:"4px"
            }}
            />
    
           
    </div>
    ) 
  
}

export default Loader