import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./common/Loader";
import isUrl from "is-url";
import { httpConstants } from "../constants";
import { Context } from "../App";
import { sessionManager } from "../managers/sessionManager";
import axios from "axios";
import DialogBox from "./common/DialogBox";
import NavBar from "./common/NavBar";

function Home() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [cookieUrl, setCookieUrl] = useState([]);

  const {
    setPhrase,
    url,
    setUrl,
    hamburgerClicked,
    count,
    setCount,
    setCurrentSession,
    currentSession,
    setLoaderVisible,
    openDialog,
    setOpenDialog,
    setMessage,
    cookieData,
    setCookieData,
  } = useContext(Context);

  useEffect(() => {
    setLoaderVisible(true);
    setOpenDialog(false);
    setMessage("");
    setProgress(0);
  }, []);

  function handleButtonClick() {
    if (url !== null && url !== "" && isUrl(url)) {
      setOpenDialog(false);

      sessionManager.setDataInCookies(url, count);
      setCount(count + 1);
      setCurrentSession({
        id: count,
        message: [...currentSession.message, url],
      });

      try {
        setLoader(true);
        // const source = axios.CancelToken.source()
        // const options={
        //   cancelToken: source.token,
        //   onDownloadProgress:function(progressEvent){
        //       //  setProgress(Math.floor(progressEvent.loaded/progressEvent.total)*100/2)
        //       // console.log(Math.floor(progressEvent.loaded/progressEvent.total)*100/2)
        //       console.log("abc",progressEvent.loaded,progressEvent.total)
        //       var percentCompleted = Math.round(progressEvent.loaded * 100 / progressEvent.total);
        //      console.log(percentCompleted)
        //   setProgress(percentCompleted)

        // }}
        fetch(
          `${
            process.env.REACT_APP_BASE_URL +
            httpConstants.API_END_POINT.GET_WORD_DENSITY +
            url
          }`
        ).then((result) => {
          result.json().then((resp) => {
            if (resp.responseCode === 200) {
              setPhrase(resp.responseData);

              setLoader(false);
              navigate("/keyword");
            } else {
              if (resp.responseCode === 500) {
                setLoader(false);
                console.log("no data", resp.success);
                // setOpenDialog(true)

                // alert("Incorrect URL");

                setMessage(resp.message);
                setOpenDialog(true);
              }
            }
            if (resp !== "") {
              setLoaderVisible(false);
            }
          });
        });
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setOpenDialog(true);
      // alert("Incorrect URL")
    }
  }
  console.log("cookieData", cookieData);

  return (

<>
    <div className="grid grid-rows-3 tablet:w-full tablet:flex tablet:flex-col mobile:w-full mobile:flex mobile:flex-col ">
      <div
        className=" flex flex-col mobile:w-full   bg-center bg-cover  "
        style={{ backgroundImage: `url(./images/home_gradient_img.svg)` }}
      >
        <div className=" mt-10 mb-32  ml-52  flex tablet:hidden mobile:hidden mobile:ml-0 gap-2.5 items-start ">
          <img src="./images/ReCrisp_logo.svg" alt="logo_image" />
          {/* <h1 className="text-white-100 font-HelveticaNeue  text-ft1">
            <text className="font-medium "> AI.</text>
            <text className=" font-light ">SEO</text>
          </h1> */}
          {/* <img src="./image/logo_img.svg" alt="" className=""/> */}
        </div>
        <div className=" mb-36 hidden tablet:flex  mobile:flex">
          <NavBar pageName='home'  />
        </div>
        <div className="   flex flex-col items-center mobile:px-4 tablet:px-4 ">
          <h2 className=" mb-2 text-white-100   font-bold  text-ft10  mobile:mb-1 mobile:text-ft1 font-HelveticaNeue ">
            <text className="  bg-gradient-to-r from-babyBlue-50 to-babyBlue-75 bg-clip-text text-transparent-25">
              AI SEO
            </text>
            <text> Optimizer</text>
          </h2>
          {/* <img src="./images/seoAnalyzer.svg" alt="" className=" mb-2" /> */}
          <text className=" text-white-100 font-normal font-HelveticaNeue w-127 mobile:w-full mobile:text-ft12 text-center  text-ft6">
            Boost your website's traffic and SEO with our AI-Powered SEO
            Optimizer; get actionable insights to optimize your keywords and
            improve your online presence.
          </text>
          <div className=" mt-12.75  mobile:justify-center mobile:mt-14 mobile:gap-2 mobile:w-full flex gap-2.5">
            <input
              type="url"
              onChange={(e) => {
                setUrl(e.target.value);

                setOpenDialog(false);
              }}
              value={url}
              placeholder="Enter URL"
              className=" focus:outline-none placeholder:text-ft7 placeholder:font-HelveticaNeue placeholder:font-medium mobile:placeholder:font-normal mobile:placeholder:text-ft9 mobile:text-ft9 placeholder:text-white-100 bg-transparent-25 text-white-100 w-116.25 mobile:w-70   h-14 border-2 border-white-100 font-medium text-ft7  pl-5  "
            />
            <button
              onClick={() => {
                handleButtonClick();
              }}
              className=" bg-white-100 w-32  mobile:w-26 h-14  "
            >
              <text className="text-center font-bold HelveticaNeue text-ft3 mobile:font-normal text-babyBlue-85 mobile:text-ft9  ">
                Analyze
              </text>
            </button>
          </div>
          <div className=" mt-12.75 mobile:mt-16 tablet:mt-16">
              <Loader page={"home"} visible={loader} value={progress} />
            </div>
        </div>
      </div>
      <div className=" flex flex-col items-center mobile:items-start  pt-32 pb-52 mobile:px-4 mobile:pt-14 mobile:pb-24 tablet:pt-20  tablet:px-4 tablet:pb-20 ">
        <img className="mobile:flex hidden tablet:flex place-self-center tablet:w-60" src="./images/How_it_works_mobile.svg" alt="how_it_works_img"/>
        <img
          className="mobile:hidden tablet:hidden"
          src="./images/How_it_works.svg"
          alt="how_it_works_img"
        />
        <div className="grid  grid-cols-4 tablet:grid-cols-2 tablet:mt-2  tablet:gap-4 tablet:mx-auto mobile:grid-cols-1 gap-8 mt-13.25 mx-40 mobile:mx-auto mobile:mt-2 ">
          <div className="flex flex-col   items-center  gap-3 ">
            <img
              src="./images/how_it_works1.svg"
              alt="image_1"
              className=" h-70"
            />
            <text className=" w-44   text-center  font-HelveticaNeue font-normal text-ft3 tablet:text-ft5  mobile:text-ft5 ">
              Enter your URL into the AI SEO Optimizer tool
            </text>
          </div>
          <div className="flex flex-col   items-center  gap-3 ">
            <img
              src="./images/how_it_works2.svg"
              alt="image_2"
              className="  h-70 "
            />
            <text className=" w-68 text-center font-HelveticaNeue font-normal text-ft3 mobile:text-ft5 tablet:text-ft5 ">
              Select your keywords from the suggested list or enter your own
            </text>
          </div>
          <div className="flex flex-col  items-center  gap-3 ">
            <img
              src="./images/how_it_works3.svg"
              alt="image_3"
              className=" h-70 "
            />
            <text className=" w-72 text-center font-HelveticaNeue font-normal text-ft3 mobile:text-ft5 tablet:text-ft5 ">
              The tool will analyze and suggest changes for your website's
              content for your chosen keywords
            </text>
          </div>
          <div className="flex flex-col  items-center  gap-3">
            <img
              src="./images/how_it_works4.svg"
              alt="image_4"
              className=" h-70 "
            />
            <text className=" w-70 text-center font-HelveticaNeue font-normal text-ft3 mobile:text-ft5 tablet:text-ft5">
              Review the suggested changes and make the necessary updates to
              your website's content
            </text>
          </div>
        </div>
      </div>
      <div className="flex  justify-center items-center bg-black-25 mobile:px-4 mobile:py-20 tablet:px-5 tablet:py-20">
        <div className="grid grid-cols-3 mx-50.75 tablet:grid-cols-2 tablet:mx-0 tablet:gap-28 mobile:grid-cols-1 mobile:mx-0 gap-45.5 mobile:gap-28 ">
          <div className="flex flex-col  ">
            <img
              src="./images/efficiency.svg"
              alt="img_efficiency"
              className="w-18.75 h-18.75"
            />
            <h4 className="mt-6 font-HelveticaNeue font-medium text-ft4 tablet:text-ft7 mobile:text-ft7 text-white-100">
              Increased Efficiency
            </h4>
            <p className="text-white-100 mt-2.5 mobile:mt-3.5 font-HelveticaNeue font-normal text-ft5 mobile:text-ft12 tablet:text-ft12  ">
              Our AI-Powered Keyword Optimization tool streamlines the SEO
              process by allowing you to input your target keywords and
              providing you with optimized versions of your website that are
              fully optimized for those keywords. This eliminates the need for
              time-consuming manual keyword optimization, making your SEO
              efforts more efficient.
            </p>
          </div>
          <div className="flex flex-col ">
            <img
              src="./images/accuracy.svg"
              alt="img_accuracy"
              className="w-18.75 h-18.75"
            />
            <h4 className="mt-6 font-HelveticaNeue font-medium text-ft4 mobile:text-ft7 tablet:text-ft7 text-white-100">
              Improved Accuracy
            </h4>
            <p className="text-white-100 mobile:mt-3.5  mt-2.5 font-HelveticaNeue font-normal text-ft5 mobile:text-ft12 tablet:text-ft12 ">
              By using advanced AI technology to analyze your website and target
              keywords, our tool is able to provide highly accurate optimization
              suggestions. Our tool is constantly learning and improving,
              ensuring that you are receiving the most relevant and impactful
              keyword optimization suggestions possible.
            </p>
          </div>
          <div className="flex flex-col ">
            <img
              src="./images/insights.svg"
              alt="img_insights"
              className=" w-18.75 h-18.75"
            />
            <h4 className="mt-6 font-HelveticaNeue font-medium text-ft4 mobile:text-ft7 tablet:text-ft7 text-white-100">
              Deeper Insights
            </h4>
            <p className="text-white-100  mobile:mt-3.5 mt-2.5 font-HelveticaNeue font-normal text-ft5 mobile:text-ft12 tablet:text-ft12 ">
              Our Keyword Optimization tool provides valuable insights into your
              website's SEO performance. By analyzing your website and target
              keywords, our tool can identify potential areas for improvement
              and suggest effective optimization strategies. This helps you make
              informed decisions about your SEO strategy and ensures that your
              website is fully optimized for your target keywords.
            </p>
          </div>
        </div>
      </div>
    </div>
    <DialogBox
          showDialog={openDialog}
          message={`Please Enter Correct URL`}
        />
    </>
  );
}

export default Home;
